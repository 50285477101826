import { memo } from "react";

import BoolCellRenderer from "components/DataGrid/customRenderers/BoolCellRenderer";
import ImageUploader from "components/ImageUploader";
import DeliverablePlatformSelect from "features/deliverables/DeliverablePlatformSelect";
import { titleCaseFormatter } from "helpers/formatters";

export const deliverableFields = [
  {
    fieldKey: "name",
    type: "text",
    componentProps: {
      creatorVisible: true,
      label: "Title",
    },
    editing: {
      required: true,
    },
  },
  {
    fieldKey: "network",
    type: "select",
    componentProps: {
      label: "Platform",
      creatorVisible: true,
    },
    editing: {
      required: true,
      disableCondition: (values) => {
        return values?.isComplete;
      },
      CustomRenderer: memo(DeliverablePlatformSelect),
    },
  },
  {
    fieldKey: "postUrl",
    componentProps: { label: "Post URL", creatorVisible: true },
    type: "text",
    editing: {
      disableCondition: (values) => {
        return values?.isComplete;
      },
    },
  },
  {
    fieldKey: "handle",
    componentProps: { label: "Handle", creatorVisible: true },
    type: "text",
    editing: {
      disableCondition: (values) => {
        return values?.isComplete;
      },
    },
  },
  {
    fieldKey: "postDate",
    componentProps: { label: "Date Posted", creatorVisible: true },
    type: "date",
    editing: {
      disableCondition: (values) => {
        return values?.isComplete;
      },
    },
  },
  {
    fieldKey: "images",
    componentProps: { label: "Screenshot(s)", creatorVisible: true },
    type: "text",
    editing: {
      disableCondition: (values) => {
        return values?.isComplete;
      },
      defaultValue: [],
      CustomRenderer: memo(ImageUploader),
    },
  },
  {
    fieldKey: "imageUrls",
    type: "array",
    editing: {
      hidden: true,
      defaultValue: [],
    },
  },
  {
    fieldKey: "uploadedImages",
    // label: "Uploaded Image Files",
    type: "array",
    editing: {
      hidden: true,
      defaultValue: [],
    },
  },
  {
    // TODO: grace period validation
    fieldKey: "isComplete",
    componentProps: { label: "Completed?", creatorVisible: true },
    type: "switch",
    editing: true,
  },
  {
    fieldKey: "markedCompleteByType",
    componentProps: { label: "Completed By", creatorVisible: true },
    type: "text",
    hideCondition: (values) => {
      const shouldHide = !values?.markedCompleteByType;
      return shouldHide;
    },
    editing: {
      disableCondition: () => true,
    },
  },
];

export const deliverableColDefs = [
  {
    headerName: "Title",
    field: "name",
    type: "text",
  },
  {
    headerName: "Platform",
    field: "network",
    type: "text",
    valueFormatter: (params) => {
      // TODO: add platform/network display name dict
      if (params?.value === "youtube") return "YouTube";
      if (params?.value === "tiktok") return "TikTok";
      return titleCaseFormatter(params.value);
    },
  },
  {
    headerName: "Handle",
    field: "handle",
    type: "text",
  },
  {
    headerName: "Date Posted",
    field: "postDate",
    type: "date",
  },
  {
    headerName: "Complete?",
    field: "isComplete",
    type: "boolean",
    cellRenderer: memo(BoolCellRenderer),
  },
  {
    headerName: "Completed By",
    field: "markedCompleteByType",
    type: "text",
    valueFormatter: (params) => {
      return titleCaseFormatter(params.value);
    },
  },
  {
    headerName: "Screenshots",
    field: "imageUrls",
    type: "number",
    valueFormatter: (params) => {
      return params.value?.length || 0;
    },
  },
  {
    headerName: "Post URL",
    field: "postUrl",
    type: "text",
    // TODO custom renderer for link
  },
];

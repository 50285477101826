/**
 * Converts an object into FormData, properly handling arrays and files
 * @param {Object} data - Object to convert to FormData
 * @returns {FormData} Formatted form data
 */
export const objectToFormData = (data) => {
  const formData = new FormData();
  const fileFields = [];
  const regularFields = [];

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value) && value.length && value[0] instanceof File) {
      fileFields.push([key, value]);
    } else {
      regularFields.push([key, value]);
    }
  });

  // Append regular fields first
  regularFields.forEach(([key, value]) => {
    if (Array.isArray(value) && value.length) {
      // array values must be appended as separate fields
      value.forEach((item) => {
        formData.append(key, item);
      });
    } else {
      formData.append(key, value);
    }
  });

  // Append file fields last for Multer to process the entire payload correctly (fields after files are ignored by backend)
  fileFields.forEach(([key, files]) => {
    files.forEach((file) => {
      formData.append(key, file);
    });
  });

  return formData;
};
